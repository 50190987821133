import axios from "axios";
import Config from "@/Configuration/Config";

export default {
    allUrl: Config.apiBaseUrl + "api/Design/All",
    removeUrl: Config.apiBaseUrl + "api/Design/Remove",
    addOrUpdateUrl: Config.apiBaseUrl + "api/Design/AddOrUpdate",
    getAllUrl: Config.apiBaseUrl+"api/Design/GetAll",
    all(filter) {
        return axios.post(this.allUrl, filter)
    },
    getAll(filter) {
        return axios.post(this.getAllUrl, filter)
    },

    addOrUpdate(design) {
        return axios.post(this.addOrUpdateUrl, design);
    },
    remove(id) {
        const fd = new FormData();
        fd.append("designId", id)
        return axios({
            method: "post",
            url: this.removeUrl,
            data: fd,
            headers: {
                "Content-Type": "multipart/form-data",
                Accept: "application/json",
            }
        });
    }
}