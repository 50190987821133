<template>
  <div class="container-fluid">
    <div class="row align-items-center">
      <div class="col-12 col-md-8">
        <h3 class="text-uppercase">Designs</h3>
      </div>
      <div class="col-12 col-md-4 d-flex">
        <el-input v-model="filter.filterText" @clear="getAll()" placeholder="search here..." @keyup="getAll()" clearable
                  class="me-1">
          <template #append>
            <el-button type="primary">
              <i class="bi bi-search"></i>
            </el-button>
          </template>
        </el-input>
        <el-button @click="resetDesign(), addDesignDialog = true" type="primary">Add Design</el-button>

      </div>
    </div>
    <el-divider class="mt-1"></el-divider>
    <el-table :data="designs" :fit="true" max-height="80vh"
              class="cstm-table" header-cell-class-name="tbl-header" size="small" stripe
    >
      <template #empty>
        <el-empty :image-size="100" class="py-1">
          <template #description>
            <span class="my-0">No Data Found</span>
          </template>
        </el-empty>
      </template>
      <template #append>
        <div class="d-flex justify-content-center py-2 px-1">
          <el-pagination
              v-if="designs.length > 0"
              v-model:current-page="pagination.page"
              v-model:page-size="pagination.pageSize"
              :page-sizes="[25, 50, 100]"
              :total="pagination.fetched"
              background
              layout="prev, pager, next, ,sizes"
              small
          />
        </div>
      </template>
      <el-table-column label="#" type="index" width="55" align="center"></el-table-column>
      <el-table-column label="Title" prop="title"></el-table-column>
      <el-table-column align="center" label="Action" width="120px">
        <template #default="scope">
          <el-space>
            <el-tooltip content="Edit">
              <el-icon @click="takeData(scope.row,'edit')">
                <i class="mgc_edit_line text-secondary action-icon fs-6"></i>
              </el-icon>
            </el-tooltip>

            <el-tooltip content="Delete">
              <el-icon color="red" @click="takeData(scope.row,'del')">
                <i class="mgc_delete_2_line action-icon fs-6"></i>
              </el-icon>
            </el-tooltip>
          </el-space>
        </template>
      </el-table-column>
    </el-table>


    <div v-if="loading" class="spinner-border d-loader"></div>

    <!--    Dialogs-->
    <el-dialog  :align-center="true"  v-model="addDesignDialog" width="600px" title="Add Design" draggable>
      <template #default>
        <div class="container">
          <div class="row">
            <div class="col-12 col-md-8 px-0">
              <div>
                <div class="d-flex flex-column">
                  <el-form label-position="top">
                    <el-form-item label="Title">
                      <el-input v-model="qrCode.title"></el-input>
                    </el-form-item>
                    <el-form-item>
                      <template #label>
                        <div class="d-flex justify-content-between align-items-center">
                          <label for="">Corners Settings</label>
                          <el-tooltip content="Enable Corner Gradient">
                            <el-switch label="Gradient" v-model="qrCode.cornerGradient"
                                       @change="changCornerGradient()"></el-switch>
                          </el-tooltip>
                        </div>
                      </template>
                      <el-select class="w-100" style="width: 250px" v-model="qrCode.corner.cornersSquareOptions.type"
                                 @change="updateQrcode = new Date()">
                        <el-option v-for="type in corners" :key="type.type" :label="type.text"
                                   :value="type.value"></el-option>
                      </el-select>
                      <el-select v-if="qrCode.cornerGradient" class="w-100 mt-1" style="width: 250px"
                                 v-model="qrCode.corner.cornersSquareOptions.gradient.type"
                                 @change="changeCornerGradientType">
                        <el-option v-for="type in gradientsTypes" :key="type.value" :label="type.text"
                                   :value="type.value"></el-option>
                      </el-select>
                      <div class="d-flex mt-1 w-100">
                        <div class="mt-1 d-flex w-100">
                          <el-input disabled
                                    :placeholder="qrCode.corner.cornersSquareOptions.gradient.colorStops[0].color">
                          </el-input>

                          <input type="color" class="cstm-color-switch" name="" id="" @input="changeCornerColor"
                                 v-model="qrCode.corner.cornersSquareOptions.gradient.colorStops[0].color">

                        </div>
                        <div v-if="qrCode.cornerGradient" class="mt-1 ms-1 d-flex w-100">
                          <el-input disabled
                                    :placeholder="qrCode.corner.cornersSquareOptions.gradient.colorStops[1].color">
                          </el-input>
                          <input type="color" class="cstm-color-switch" name="" id=""
                                 @input="updateQrcode = new Date()"
                                 v-model="qrCode.corner.cornersSquareOptions.gradient.colorStops[1].color">

                        </div>
                      </div>
                    </el-form-item>
                    <el-form-item label="Primary Settings">
                      <template #label>
                        <div class="d-flex justify-content-between align-items-center">
                          <label for="">Primary Settings</label>
                          <el-tooltip content="Enable Primary Gradient">
                            <el-switch label="Gradient" v-model="qrCode.primaryGradient"
                                       @change="changePrimaryGradient()"></el-switch>
                          </el-tooltip>
                        </div>
                      </template>
                      <el-select class="w-100" style="width: 250px" v-model="qrCode.dotsOptions.type"
                                 @change="updateQrcode = new Date()">
                        <el-option v-for="type in dots" :key="type.type" :label="type.text"
                                   :value="type.value"></el-option>
                      </el-select>
                      <el-select v-if="qrCode.primaryGradient" class="w-100 mt-1" style="width: 250px"
                                 v-model="qrCode.dotsOptions.gradient.type"
                                 @change="updateQrcode = new Date()">
                        <el-option v-for="type in gradientsTypes" :key="type.value" :label="type.text"
                                   :value="type.value"></el-option>
                      </el-select>
                      <div class="d-flex w-100">
                        <div class="mt-1 d-flex w-100">
                          <el-input disabled :placeholder="qrCode.dotsOptions.gradient.colorStops[0].color">
                          </el-input>
                          <input type="color" class="cstm-color-switch"
                                 v-model="qrCode.dotsOptions.gradient.colorStops[0].color" name="" id=""
                                 @input="changePrimaryColor()">
                        </div>
                        <div v-if="qrCode.primaryGradient" class="mt-1 ms-1 d-flex w-100">
                          <el-input disabled :placeholder="qrCode.dotsOptions.gradient.colorStops[1].color">
                          </el-input>
                          <!--                    <el-color-picker color-format="rgb" @change="updateQrcode = new Date()"-->
                          <!--                                     v-model="qrCode.dotsOptions.gradient.colorStops[1].color" show-alpha/>-->
                          <input type="color" class="cstm-color-switch" name="" id="" @input="updateQrcode = new Date()"
                                 v-model="qrCode.dotsOptions.gradient.colorStops[1].color">
                        </div>
                      </div>
                    </el-form-item>
                    <el-form-item>
                      <template #label>Logo</template>
                      <img width="60" height="60" v-if="isBase64Image(qrCode.image)" :src="qrCode.image" alt="">
                      <input type="file" id="upload-photo" class="d-none" :key="fileInputKey"
                             ref="uploader1"
                             @change="onFileChanged($event)">
                      <el-button style="width: 60px !important; height: 60px !important; border: 1px dashed gray" v-if="!isBase64Image(qrCode.image)" @click="handleFileImport()" bg text>
                        <i class="bi bi-plus"></i>
                      </el-button>
                      <el-button bg text class="ms-2" size="small" v-if="isBase64Image(qrCode.image)" @click="qrCode.image = '', updateQrcode= new Date()">
                        <i class="bi bi-x"></i>
                      </el-button>
                    </el-form-item>
                  </el-form>

                </div>

              </div>
              <!--            <el-form>-->
              <!--              <el-form-item label="Corner Type">-->
              <!--                <el-select style="width: 250px" v-model="qrCode.corner.type" @change="updateQrcode = new Date()">-->
              <!--                  <el-option v-for="type in corners" :key="type.type" :label="type.text"-->
              <!--                             :value="type.value"></el-option>-->
              <!--                </el-select>-->
              <!--              </el-form-item>-->
              <!--              <el-form-item label="Color">-->
              <!--                <el-color-picker @change="updateQrcode = new Date()" on-update:model-value="#fff"-->
              <!--                                 v-model="qrCode.corner.color" show-alpha/>-->
              <!--              </el-form-item>-->
              <!--            </el-form>-->
            </div>
            <div class="col-12 col-md-4 px-0 d-flex align-items-center justify-content-end">
              <QRCodeVue3 ref="qrcode" :width="170" :height="170" :value="link" :key="updateQrcode"
                          :qrOptions="{ typeNumber: 0, mode: 'Byte', errorCorrectionLevel: 'H' }"
                          :imageOptions="{ hideBackgroundDots: true, imageSize: 0.4, margin: 0 }"

                          :dotsOptions="qrCode.dotsOptions"
                          v-bind:image="qrCode.image" :backgroundOptions="{ color: '#ffffff' }"
                          :cornersSquareOptions="qrCode.corner.cornersSquareOptions"
                          :cornersDotOptions="qrCode.corner.cornersDotOptions" fileExt="png"

                          myclass="my-qur"
                          imgclass="img-qr" downloadButton="btn btn-primary"
                          :downloadOptions="{ name: 'vqr', extension: 'webp' }"/>
            </div>
          </div>
        </div>

      </template>
      <template #footer>
        <div class="container-fluid px-0 d-flex justify-content-end">
          <el-button text bg @click="addDesignDialog = false">Cancel</el-button>
          <el-button type="primary" @click="addDesignDialog = false, saveDesign()">Save</el-button>
        </div>
      </template>
    </el-dialog>

    <el-dialog v-model="deleteDialog" title="Delete Design" width="450">
      <template #default>
        <span>Are you sure? You want to delete <span
            class="mfw-bold text-danger">{{ qrCode.title }}</span> design.</span>
      </template>
      <template #footer>
        <div class="container-fluid px-0 d-flex justify-content-end">
          <el-button text bg @click="deleteDialog = false">Cancel</el-button>
          <el-button type="danger" @click="deleteDialog = false, removeDesign()">Delete</el-button>
        </div>
      </template>
    </el-dialog>
  </div>
</template>
<script>
import QRCodeVue3 from "qrcode-vue3";
import designService from "@/Services/designService";
import {ShowMessage} from "@/Utility/Utility";

export default {
  name: "QrDesigns",
  components: {QRCodeVue3},
  data() {
    return {
      loading: false,
      addDesignDialog: false,
      deleteDialog: false,
      imageCode: "",
      fileInputKey: Date.now(),
      link: "https://app.magiclinkz.com",
      designs: [],
      gradientsTypes: [
        {text: "Linear", value: "linear"},
        {text: "Radial", value: "radial"},
      ],
      currentDesign: {},
      pagination: {},
      updateQrcode: new Date(),
      corners: [
        {text: "Dots", value: "dot"},
        {text: "Square", value: "square"},
        {text: "Extra Rounded", value: "extra-rounded"},
      ],
      dots: [
        {text: "Dots", value: "dot"},
        {text: "Square", value: "square"},
        {text: "Rounded", value: "rounded"},
        {text: "Extra Rounded", value: "extra-rounded"},
        {text: "Classy", value: "classy"},
        {text: "Classy Rounded", value: "classy-rounded"},
      ],
      qrCode: {
        title: "",
        image: "",
        userId: sessionStorage.getItem("userId"),
        cornerGradient: false,
        primaryGradient: false,
        corner: {
          cornersSquareOptions: {
            type: "extra-rounded",
            gradient: {
              type: 'linear',
              rotation: 0,
              colorStops: [
                {offset: 0, color: '#000'},
                {offset: 1, color: '#000'},
              ],
            },
          },
          cornersDotOptions: {
            type: "extra-rounded",
            gradient: {
              type: 'linear',
              rotation: 0,
              colorStops: [
                {offset: 0, color: '#000'},
                {offset: 1, color: '#000'},
              ],
            },
          },

        },
        dotsOptions: {
          type: 'dot',
          color: '#26249a',
          gradient: {
            type: 'linear',
            rotation: 0,
            colorStops: [
              {offset: 0, color: '#000'},
              {offset: 1, color: '#000'},
            ],
          },
        },
      },
      filter: {
        pageSize: 25,
        page: 1,
        filterText: "",
        isActive: true,
        userId: sessionStorage.getItem("userId"),
        linkType: 30,
      },
    }
  },
  methods: {
    isBase64Image(imageUrl) {
      if (imageUrl === '' || imageUrl === null || imageUrl === undefined) {
        return;
      }
      // Check if the image URL starts with the specified base64 prefix
      return imageUrl.startsWith("data:image/jpeg;base64,") || imageUrl.startsWith("data:image/png;base64,");
    },
    handleFileImport() {

      this.$refs.uploader1.click();
    },
    onFileChanged() {
      this.fileInputKey = new Date();
      const fileInput = document.getElementById("upload-photo");

      // Check if a file was selected
      if (fileInput.files && fileInput.files.length) {
        const selectedFile = fileInput.files[0];

        // Convert the selected image file to Base64
        this.convertFileToBase64(selectedFile)
            .then((base64Data) => {
              // Assign the Base64 data to this.qrCode.image
              this.qrCode.image = "data:image/png;base64," + base64Data;
              // Optionally, display a success message or perform other actions
              this.updateQrcode = new Date();
            })
            .catch((error) => {
              console.error("Error converting image to Base64:", error);
            });
      }
    },

    convertFileToBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () => resolve(reader.result.split(",")[1]); // Extract Base64 data
        reader.onerror = (error) => reject(error);
        reader.readAsDataURL(file);
      });
    },
    takeData(design, type) {
      this.qrCode = {...design};
      if (type === "del") {
        this.deleteDialog = true;
      }
      if (type === 'edit') {
        this.addDesignDialog = true;
      }

    },
    changeCornerGradientType() {
      this.qrCode.corner.cornersDotOptions.gradient.type = this.qrCode.corner.cornersSquareOptions.gradient.type
      this.updateQrcode = new Date()
    },
    changeCornerColor() {

      this.qrCode.corner.cornersDotOptions.gradient.colorStops[0].color = this.qrCode.corner.cornersSquareOptions.gradient.colorStops[0].color
      if (!this.qrCode.cornerGradient) {
        this.qrCode.corner.cornersDotOptions.gradient.colorStops[1].color = this.qrCode.corner.cornersDotOptions.gradient.colorStops[0].color;
        this.qrCode.corner.cornersSquareOptions.gradient.colorStops[1].color = this.qrCode.corner.cornersDotOptions.gradient.colorStops[0].color;
      }
      this.updateQrcode = new Date();
    },
    changePrimaryColor() {
      if (!this.qrCode.primaryGradient) {
        this.qrCode.dotsOptions.gradient.colorStops[1].color = this.qrCode.dotsOptions.gradient.colorStops[0].color;
      }
      else{
        this.qrCode.dotsOptions.gradient.colorStops[1].color = "#0d6efd"
      }
      this.updateQrcode = new Date();
    },
    changePrimaryGradient() {
      if (!this.qrCode.primaryGradient) {
        this.qrCode.dotsOptions.gradient.colorStops[1].color = this.qrCode.dotsOptions.gradient.colorStops[0].color;
      } else {
        this.qrCode.dotsOptions.gradient.colorStops[1].color = "#0d6efd"
      }
      this.updateQrcode = new Date();
    },
    changCornerGradient() {
      if (!this.qrCode.cornerGradient) {
        this.qrCode.corner.cornersDotOptions.gradient.colorStops[1].color = this.qrCode.corner.cornersDotOptions.gradient.colorStops[0].color;
        this.qrCode.corner.cornersSquareOptions.gradient.colorStops[1].color = this.qrCode.corner.cornersSquareOptions.gradient.colorStops[0].color;
      } else {

        this.qrCode.corner.cornersDotOptions.gradient.colorStops[1].color = "#0d6efd";
        this.qrCode.corner.cornersSquareOptions.gradient.colorStops[1].color = "#0d6efd";
      }
      this.updateQrcode = new Date();
    },
    async getAll() {
      try {
        this.loading = true;
        let response = await designService.getAll(this.filter);
        this.designs = response?.data?.data;
        this.pagination = response?.data?.pagination;
        this.loading = false;
      } catch (e) {
        this.loading = false;
        console.log(e)
      }
    },
    async saveDesign() {
      try {
        this.loading = true;
        let response = await designService.addOrUpdate(this.qrCode);
        if (response.status === 200) {
          ShowMessage('success', "Design Added")
        }
        this.getAll();
        this.loading = false;
      } catch (e) {
        console.log(e);
        this.loading = false;
      }
    },
    async removeDesign() {
      try {
        this.loading = true;
        let res = await designService.remove(this.qrCode.id);
        if (res.status === 200) {
          ShowMessage("success", "Design deleted successfully!");
        }
        this.getAll();
      } catch {
        ShowMessage("error", "something went wrong!");
      }
    },
    resetDesign() {
      this.qrCode = {
        title: "",
        userId: sessionStorage.getItem("userId"),
        cornerGradient: false,
        primaryGradient: false,
        corner: {
          cornersSquareOptions: {
            type: "extra-rounded",
            gradient: {
              type: 'linear',
              rotation: 0,
              colorStops: [
                {offset: 0, color: '#000'},
                {offset: 1, color: '#000'},
              ],
            },
          },
          cornersDotOptions: {
            type: "extra-rounded",
            gradient: {
              type: 'linear',
              rotation: 0,
              colorStops: [
                {offset: 0, color: '#000'},
                {offset: 1, color: '#000'},
              ],
            },
          },
        },
        dotsOptions: {
          type: 'dot',
          color: '#26249a',
          gradient: {
            type: 'linear',
            rotation: 0,
            colorStops: [
              {offset: 0, color: '#000'},
              {offset: 1, color: '#000'},
            ],
          },
        },
      };
      this.updateQrcode = new Date();
    }
  }
  ,
  created() {
    this.getAll();
  }
}
</script>

<style scoped>
.cstm-color-switch{
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  height: 35px !important;
}
</style>