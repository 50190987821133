<template>
  <div class="container-fluid">
    <div class="row align-items-center">
      <div class="col-12 col-md-8">
        <h3 class="text-uppercase">Generate Qr Code</h3>
      </div>
      <div class="col-12 col-md-4 d-flex justify-content-end">
        <!--        <el-input v-model="filter.filterText" placeholder="search here..." clearable class="me-1">
                  <template #append>
                    <el-button type="primary">
                      <i class="bi bi-search"></i>
                    </el-button>
                  </template>
</el-input>-->
        <router-link to="qrcodes">
          <el-button text bg class="me-2">
            <i class="bi bi-arrow-left-short me-2"></i>
            Cancel
          </el-button>
        </router-link>
        <el-button v-if="QrCode.linkId < 1" type="primary" @click="generateByteArray()">Add Code</el-button>
        <el-button v-else type="primary" @click="generateByteArray()">Update Code</el-button>
      </div>
    </div>
    <el-divider class="mt-1"></el-divider>
    <div class="col-12 col-md-5">
      <el-form label-position="top" v-model="QrCode">
        <!-- <div>
          <img :src="QrCode.image" alt="">
        </div> -->
        <el-form-item>
          <template #label>
            <span class="required">QR Code Name</span>
          </template>
          <el-input v-model="QrCode.title"></el-input>
        </el-form-item>
        <el-form-item>
          <template #label>
            <span class="required">Destination Url</span>
          </template>
          <el-input v-model="QrCode.trackedUrl"></el-input>
        </el-form-item>
        <el-form-item>
          <template #label>
            <span>Domain / Slug</span>
          </template>
          <el-input :disabled="QrCode.linkId > 0" v-model="QrCode.linkGuid" @keyup="updateQrcode = new Date(), generateByteArray()">
            <template #prepend>
              <el-select :disabled="QrCode.linkId > 0" style="width: 250px" v-model="QrCode.customUrl" @change="updateQrcode = new Date()">
                <el-option v-for="domain in Domains" :key="domain.id" :label="domain.name"
                           :value="domain.name"></el-option>
              </el-select>
            </template>
          </el-input>
        </el-form-item>
        <el-form-item>
          <template #label>
            <span>Select Pixel</span>
          </template>
          <el-select multiple v-model="LocalPixelCodes" placeholder="select pixel" @change="CheckPixel()">
            <el-option v-for="code in PixelCodes" :key="code.id" :label="code.codeName" :value="code"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item class="w-100">
          <template #label>
            <span>QR Code Design</span>
          </template>
          <el-select v-model="designId" @change="updateQrcode = new Date(), changeDesign()" class="mb-1" placeholder="Select QrCode Design">
            <el-option v-for="design in designs" :key="design.id" :label="design.title"
                       :value="design.id"></el-option>
          </el-select>
          <el-card shadow="never">
            <div ref="qrcodeContainer">
              <QRCodeVue3 ref="qrcode" :width="150" :height="150" :value="QrCode.customUrl + QrCode.linkGuid"
                          :key="updateQrcode"   :qrOptions="{ typeNumber: 0, mode: 'Byte', errorCorrectionLevel: 'H' }"
                          :imageOptions="{ hideBackgroundDots: true, imageSize: 0.4, margin: 0 }"

                          :dotsOptions="design.dotsOptions"
                          v-bind:image="design.image" :backgroundOptions="{ color: '#ffffff' }"
                          :cornersSquareOptions="design.corner.cornersSquareOptions"
                          :cornersDotOptions="design.corner.cornersDotOptions" fileExt="png"

                          myclass="my-qur"
                          imgclass="img-qr" downloadButton="btn btn-primary"
                          :downloadOptions="{ name: 'vqr', extension: 'webp' }"/>
            </div>
          </el-card>

          <!--          <el-card shadow="never" class="h-100 ms-3">-->
          <!--            <div>-->
          <!--              <el-switch @change="includeLogo" active-color="green" v-model="LogoUpload"-->
          <!--                active-text="Include Logo in QR Code"></el-switch>-->
          <!--            </div>-->
          <!--            <div class="d-flex align-center justify-center mt-2">-->
          <!--              <div class="d-flex align-items-center justify-content-center">-->
          <!--                <div class="me-5">-->
          <!--                  <img v-if="ImageUrl !== ''" :src="ImageUrl" width="60" height="60" alt="logo" />-->
          <!--                  <img v-else src="https://picsum.photos/id/11/100/60" width="60" height="60" alt="logo" />-->
          <!--                </div>-->
          <!--                &lt;!&ndash; 1. Create the button that will be clicked to select a file &ndash;&gt;-->
          <!--                <div>-->
          <!--                  <el-button class="primary-color caption text-capitalize" depressed :loading="loading" type="primary"-->
          <!--                    @click="handleFileImport">-->
          <!--                    Upload Logo-->
          <!--                  </el-button>-->

          <!--                  &lt;!&ndash; Create a File Input that will be hidden but triggered with JavaScript &ndash;&gt;-->
          <!--                  <input ref="uploader" class="d-none" type="file" @change="onFileChanged" />-->
          <!--                </div>-->
          <!--              </div>-->
          <!--            </div>-->
          <!--          </el-card>-->
        </el-form-item>
      </el-form>
    </div>
    <div v-if="loading" class="spinner-border d-loader"></div>
  </div>
</template>
<script>
import Config from "@/Configuration/Config";
import domainService from "@/Services/domainService";
import store from '@/store/index'
import pixelCodeService from "@/Services/pixelCodeService";
import qrCodeService from "@/Services/linkService";
import { ShowMessage } from "@/Utility/Utility";
import QRCodeVue3 from "qrcode-vue3";
import designService from "@/Services/designService";

export default {
  name: "AddCode",
  components: { QRCodeVue3 },
  data() {
    return {
      SelectedDomain: "",
      iconUrl: require('../../assets/img/logo.png'),
      loading: false,
      designs:[],
      LogoUpload: false,
      ImageUrl: "",
      LocalPixelCodes: [],
      Domains: [],
      PixelCodes: [],
      designId: "",
      updateQrcode: new Date(),
      QrCode: {
        "asin": "",
        "marketPlace": "",
        "campaignLink": "",
        "visits": 0,
        "image": "",
        "redirectionLink": "",
        "linkStatus": 0,
        "pixelCodes": [],
        "linkStats": [],
        "linkId": 0,
        "title": "",
        "baseUrl": "",
        "logo": "",
        "linkGuid": "",
        "customUrl": Config.apiBaseUrl,
        "linkType": 10,
        "userId": sessionStorage.getItem("userId"),
        "trackedUrl": "",
        "pixelCode": "",
        "isActive": true,
        //domainName: "https://mglz.us",
        //name: "https://mglz.us",
        // domainName: "https://mglz-dev.azurewebsites.net",
        // name: "https://mglz-dev.azurewebsites.net",
        "domainName": Config.apiBaseUrl,
        "name": Config.apiBaseUrl,
      },
      design: {
        id: 0,
        title: "",
        image: "",
        userId: sessionStorage.getItem("userId"),
        cornerGradient: false,
        primaryGradient: false,
        corner: {
          cornersSquareOptions: {
            type: "extra-rounded",
            gradient: {
              type: 'linear',
              rotation: 0,
              colorStops: [
                {offset: 0, color: '#000'},
                {offset: 1, color: '#000'},
              ],
            },
          },
          cornersDotOptions: {
            type: "extra-rounded",
            gradient: {
              type: 'linear',
              rotation: 0,
              colorStops: [
                {offset: 0, color: '#000'},
                {offset: 1, color: '#000'},
              ],
            },
          },

        },
        dotsOptions: {
          type: 'dot',
          color: '#26249a',
          gradient: {
            type: 'linear',
            rotation: 0,
            colorStops: [
              {offset: 0, color: '#000'},
              {offset: 1, color: '#000'},
            ],
          },
        },
      },
      filter: {
        pageSize: 25,
        page: 1,
        filterText: "",
        isVerified: true,
        userId: sessionStorage.getItem("userId"),
        isActive: true,
      },
      isComponentMounted: false
    }
  },
  mounted() {
    this.isComponentMounted = true;
  },
  methods: {
    changeDesign(){
      const selectedDesign = this.designs.find(design => Number(design.id) === Number(this.designId));
      this.design = selectedDesign;
      if (selectedDesign) {
        this.design = selectedDesign;
      } else {
        console.log(`Design with id ${this.designId} not found.`);
      }
    },
    isBase64Image(imageUrl) {
      if(imageUrl === '' || imageUrl === null || imageUrl === undefined){
        return;
      }
      // Check if the image URL starts with the specified base64 prefix
      return imageUrl.startsWith("data:image/jpeg;base64,") || imageUrl.startsWith("data:image/png;base64,");
    },
    includeLogo() {
      if (this.LogoUpload) {
        this.QrCode.logo = this.ImageUrl;
        this.updateQrcode = new Date();
      }
      else {
        this.QrCode.logo = "";
        this.updateQrcode = new Date();
      }
    },
    async generateByteArray() {
      try {
        let response = await qrCodeService.checkSlug(this.QrCode.linkGuid, this.QrCode.linkId);
        this.QrCode.linkGuid = response.data;
        this.updateQrcode = new Date();

        // Wait for the QR code generation to complete
        await this.$nextTick();

        // Fetch the image source after waiting for the asynchronous operations
        await new Promise(resolve => setTimeout(resolve, 0)); // This micro-task ensures the next tick
        const imgSrc = document.querySelector('.img-qr').getAttribute('src');

        this.QrCode.qrCode = imgSrc;
        this.CreateURL();

        // Now that the image is updated, you can call CreateURL
        // this.CreateURL();
      } catch (error) {
        console.error('Error generating Base64 string:', error);
      }
    },


    CheckPixel() {
      this.QrCode.pixelCodes = [];
      this.LocalPixelCodes.forEach(element => {
        let object = {
          pixelId: element.id
        }
        this.QrCode.pixelCodes.push(object)
      });
    },
    async CreateURL() {
      try {
        if (this.LogoUpload === false) {
          this.QrCode.logo = null;
        }
        if (!this.QrCode.title || !this.QrCode.trackedUrl) {
          ShowMessage("error", "Please enter the required fields!");
          return;
        }
        /*if (!this.QrCode.customUrl.includes("https")) {
          let http = this.QrCode.customUrl;
          this.QrCode.customUrl = "https://" + http ;

        }*/
        this.QrCode.user = null;
        if (this.QrCode.customUrl === null || this.QrCode.customUrl === "") {
          this.QrCode.customUrl = Config.apiBaseUrl;
        }
        // const lastChar = this.QrCode.customUrl.slice(-1);
        // if (lastChar === '/') {
        //   this.QrCode.customUrl = this.QrCode.customUrl.replace(/\/$/, "");
        // }
        if (!this.QrCode.customUrl.startsWith("https://") && !this.QrCode.customUrl.startsWith("http://")) {
          // If not, add "https://" to the beginning of the string
          this.QrCode.customUrl = "https://" + this.QrCode.customUrl;
        }
        this.loading = true;
        let response = await qrCodeService.addOrUpdate(this.QrCode);

        if (response.data.isSuccessful === false) {
          ShowMessage("error", response.data.message)
        } else {
          ShowMessage("success", "Qr Code created successfully.")
          this.ClearForm();
        }
        this.loading = false;
      } catch (error) {
        ShowMessage("error", error.message)
        this.loading = false;
      }
    },
    ClearForm() {
      this.QrCode = {
        title: "",
        baseUrl: "",
        logo: "",
        linkGuid: "",
        pixelCodes: [],
        customUrl: Config.apiBaseUrl,
        linkType: 10,
        userId: localStorage.getItem("userId"),
        pixelCode: "",
        domainName: Config.apiBaseUrl,
        name: Config.apiBaseUrl,
        /* domainName: "https://mglz.azurewebsites.net/",
         name: "https://mglz.azurewebsites.net/",*/
      };
      this.LocalPixelCodes = [];
    },

    async GetDomains() {
      try {
        this.loader = true;
        let response = await domainService.all(this.filter)
        this.Domains = response.data.data;
        this.loader = false;
      } catch (e) {
        this.loader = false;
        console.log(e);
      }
    },
    async GetPixels() {
      try {
        this.loader = true;
        let response = await pixelCodeService.getAll(this.filter)
        this.PixelCodes = response.data.data;
        this.loader = false;
      } catch (e) {
        console.log(e);
      }
    },
    handleFileImport() {
      this.loader = true;
      // After obtaining the focus when closing the FilePicker, return the button state to normal
      window.addEventListener(
          "focus",
          () => {
            this.isSelecting = false;
          },
          { once: true }
      );
      // Trigger click on the FileInput
      this.$refs.uploader.click();

      this.loader = false;
    },
    onFileChanged(file) {
      if (!file) {
        return;
      }
      this.createImage(file);
    },

    createImage(file) {
      this.loader = true;
      const reader = new FileReader();

      reader.onload = () => {
        // Make sure to directly set QrCode.logo without splitting the result
        this.ImageUrl = reader.result;
        if (this.LogoUpload) {
          this.QrCode.logo = reader.result;
          this.loader = false;
          this.updateQrcode = new Date();
        }
      };

      reader.readAsDataURL(file.target.files[0]);
      this.isSelecting = false;
    },

    async GetLink() {
      try {
        this.loading = true;
        let res = await qrCodeService.getLink(store.state.pageId);
        this.QrCode = res.data.data;
        if (this.QrCode.logo !== null) {
          this.LogoUpload = true
        }
        if (this.QrCode.pixelCodes != null && this.QrCode.pixelCodes.length > 0) {
          this.QrCode.pixelCodes.forEach(element => {
            this.LocalPixelCodes.push(element)
          })
        }
        if (this.QrCode.logo != null || this.QrCode.logo != "") {
          this.ImageUrl = this.QrCode.logo;

        }
        this.updateQrcode = new Date(),
            this.loading = false;
      } catch (e) {
        this.loading = false;
        console.log(e)
      }
    },
    async getAllDesigns() {
      try {
        this.loading = true;
        let response = await designService.all(this.filter);
        this.designs = response?.data?.data;
        this.loading = false;
      } catch (e) {
        this.loading = false;
        console.log(e)
      }
    },
  },
  created() {
    if (store.state.pageId > 0) {
      this.GetLink();
    }
    this.GetPixels();
    this.GetDomains();
    this.getAllDesigns();
  }
}
</script>

<style scoped></style>